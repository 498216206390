import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function SupportHeading({ children }: Props) {
  return (
    <h6 className="text-lg font-semibold leading-6 text-orange-500">
      {children}
    </h6>
  )
}

export default SupportHeading
