import React, { forwardRef, Ref } from "react"

interface Props {
  id?: string
  type?: string
  name?: string
  placeholder: string
  ref: Ref<HTMLInputElement>
}

const Input = forwardRef(
  (
    { type, name, placeholder, id }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <input
        id={id}
        className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
        name={name}
        type={type}
        placeholder={placeholder}
        ref={ref}
      />
    )
  }
)
Input.displayName = "Input"

export default Input
