import React from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"
import FormError from "./FormError"
import Input from "./Input"

interface Props {}

function encode(data: {
  [x: string]: string | number | boolean
  "form-name"?: string
}) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

function ContactForm({}: Props) {
  const { register, handleSubmit, errors } = useForm()

  const onSubmit = handleSubmit((data, event) => {
    const form = event.target

    try {
      if (typeof window !== "undefined") {
        window.gtag("event", "submit", {
          event_category: "Contact Form",
          event_label: "Enviar",
        })
      }
    } catch (e) {
      console.log(e)
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": form.getAttribute("name"), ...data }),
    })
      .then(() => {
        navigate(form.getAttribute("action"))
      })
      .catch(error => alert(error))
  })

  return (
    <form
      name="contact"
      method="POST"
      action="/gracias-por-contactarnos"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={onSubmit}
    >
      <input type="hidden" name="form-name" value="contact" />
      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="name"
          >
            Nombre
          </label>
          <input
            className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            id="name"
            type="text"
            name="name"
            placeholder="Andrés"
            ref={register({ required: true })}
          />
          {errors.name && <FormError>Por favor llena este campo.</FormError>}
        </div>
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="grid-last-name"
          >
            Apellido
          </label>
          <input
            className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            id="lastname"
            type="text"
            placeholder="González"
            name="lastname"
            ref={register({ required: true })}
          />
          {errors.lastname && (
            <FormError>Por favor llena este campo.</FormError>
          )}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="email"
          >
            email
          </label>
          <Input
            id="email"
            type="text"
            name="email"
            placeholder="contacto@grancolmado.com.co"
            ref={register({ required: true })}
          />
          {errors.email && <FormError>Por favor llena este campo.</FormError>}
        </div>
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="Phone"
          >
            Teléfono
          </label>
          <Input
            name="Phone"
            type="text"
            placeholder="(+57) 1 2644464"
            ref={register({ required: true })}
          />
          {errors.phone && <FormError>Por favor llena este campo.</FormError>}
        </div>
      </div>
      <div className="flex flex-wrap -mx-3">
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="company"
          >
            Empresa
          </label>
          <Input
            id="company"
            type="text"
            name="company"
            placeholder="Distrigranos"
            ref={register({ required: true })}
          />
          {errors.company && <FormError>Por favor llena este campo.</FormError>}
        </div>
        <div className="w-full px-3 mt-3 md:w-1/2">
          <label
            className="block mt-2 text-xs font-bold tracking-wide text-gray-700 uppercase"
            htmlFor="topic"
          >
            Motivo
          </label>
          <div className="relative">
            <select
              id="topic"
              className="block w-full px-4 py-3 pr-8 mt-1 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              name="topic"
            >
              <option>Cotización</option>
              <option>Información</option>
              <option>Otro</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
              <svg
                className="w-4 h-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-6">
        <label
          className="block text-xs font-bold tracking-wide text-gray-700 uppercase"
          htmlFor="Email"
        >
          Comnetarios
        </label>
        <textarea
          id="comments"
          className="block w-full px-4 py-3 mt-1 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
          name="comments"
          placeholder="Pregunta sobre disponibilidad de productos, inventarios, precios, envíos y mucho más."
          ref={register({ required: true })}
        />
        {errors.lastname && <FormError>Por favor llena este campo.</FormError>}
      </div>
      <div className="flex justify-end mt-4">
        <input
          className="inline-flex items-center justify-center px-3 py-2 text-base font-semibold leading-5 text-white whitespace-no-wrap transition duration-150 ease-in-out bg-orange-500 border border-transparent rounded cursor-pointer hover:bg-orange-400 focus:outline-none focus:border-orange-600 focus:shadow-outline-orange active:bg-orange-700"
          type="submit"
          value="Enviar"
        />
      </div>
    </form>
  )
}

export default ContactForm
