import React, { ReactNode } from "react"

interface Props {
  children: ReactNode
}

function FormError({ children }: Props) {
  return <p className="text-xs italic text-red-500">{children}</p>
}

export default FormError
