import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import ContactForm from "../components/ContactForm"
import PageHeading from "../components/PageHeading"
import SupportHeading from "../components/SupportHeading"
import Helmet from "react-helmet"

type TemplateProps = {
  page: {
    title: string
    subtitle: string
    firstTitle: string
    firstText: string
    secondTitle: string
    secondText: string
  }
}

export function ContactanosPageTemplate({ page }: TemplateProps) {
  const {
    title,
    subtitle,
    firstTitle,
    firstText,
    secondTitle,
    secondText,
  } = page

  return (
    <>
      <div className="pt-16 mb-16 hero-section">
        <div className="flex flex-col w-full max-w-5xl px-8 mx-auto mt-12 md:flex-row md:px-2">
          <div className="w-full md:w-1/2">
            <SupportHeading>Atención personalizada</SupportHeading>

            <div className="mt-4">
              <PageHeading>{title}</PageHeading>
            </div>

            <div className="mt-6">
              <div className="p-6 bg-white rounded-sm shadow-md">
                <ContactForm />
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <div className="mt-16 md:ml-16">
              <div>
                <h2 className="text-2xl font-bold text-light font-display">
                  {firstTitle}
                </h2>
                <p className="mt-2 text-white">{firstText}</p>
                <div className="mt-2 font-bold leading-4 text-white">
                  Bogotá
                  <span className="mx-1 text-base font-bold text-orange-500">
                    &gt;
                  </span>
                  (+57) 1 2644464
                </div>
              </div>
              <div className="mt-6">
                <h2 className="text-2xl font-bold font-display text-light">
                  {secondTitle}
                </h2>
                <p className="mt-2 text-white">{secondText}</p>
                <p className="font-bold text-white">
                  Av Carr 80 # 2 – 51, Bodega 7, Local 14.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg)",
            transformStyle: "preserve-3d",
            opacity: 1,
          }}
          className="gradient-bg gradient-bg-contact-us"
        ></div>
        <div
          className="gradient-bg-cut"
          style={{ bottom: -33, height: 70, left: -2, width: "auto" }}
        ></div>
      </div>
    </>
  )
}

type PageProps = {
  data: any
}

export default function ContactanosPage({ data }: PageProps) {
  const { frontmatter: page } = data.pageData.edges[0].node

  return (
    <Layout>
      <SEO title={page.seo.title} description={page.seo.description} />
      <ContactanosPageTemplate page={page} />
    </Layout>
  )
}

export const productosPageQuery = graphql`
  query contactanosPageQuery {
    pageData: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "contactanos-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            firstTitle
            firstText
            secondTitle
            secondText
            seo {
              title
              description
            }
          }
        }
      }
    }
  }
`
